<div>
    <div mat-dialog-title class="mat-dialog-title">
        <div>Edit Task Dates  
            <button class="ig2 mat-button-tooltip" mat-icon-button type="button" matTooltipClass="ng-trigger" [matTooltip]="'All date and times are in Local time zone'" aria-label="Button that displays a tooltip when focused or hovered over">
               <span><svg width="16" height="15" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 0C16.023 0 20.5 4.477 20.5 10C20.5 15.523 16.023 20 10.5 20C4.977 20 0.5 15.523 0.5 10C0.5 4.477 4.977 0 10.5 0ZM10.5 1C9.3181 1 8.14778 1.23279 7.05585 1.68508C5.96392 2.13738 4.97177 2.80031 4.13604 3.63604C3.30031 4.47177 2.63738 5.46392 2.18508 6.55585C1.73279 7.64778 1.5 8.8181 1.5 10C1.5 11.1819 1.73279 12.3522 2.18508 13.4442C2.63738 14.5361 3.30031 15.5282 4.13604 16.364C4.97177 17.1997 5.96392 17.8626 7.05585 18.3149C8.14778 18.7672 9.3181 19 10.5 19C12.8869 19 15.1761 18.0518 16.864 16.364C18.5518 14.6761 19.5 12.3869 19.5 10C19.5 7.61305 18.5518 5.32387 16.864 3.63604C15.1761 1.94821 12.8869 1 10.5 1ZM11.635 7.618V15.8H9.365V7.618H11.635ZM10.505 4.268C11.182 4.268 11.735 4.784 11.735 5.413C11.735 6.047 11.182 6.563 10.505 6.563C9.835 6.563 9.28 6.047 9.28 5.413C9.28 4.784 9.834 4.268 10.505 4.268Z" fill="#043B7C"/>
                </svg></span> 
            </button>
        </div>
        <img mat-dialog-close class="dialog__header-icon" src="assets/icons/icon-close-dark.svg" alt="close icon" />
    </div>
    <mat-dialog-content class="mat-typography mdc">
        <div class="dialog-container">
            <form [formGroup]="EditTaskDatesForm" autocomplete="off" novalidate>
                <mat-form-field id="due-date" class="ig2 mat-form-field-no-animation mat-form-field-datepicker" appearance="fill">
                    <mat-label>Due Date </mat-label>
                    <input class="mat-input-element-show-placeholder" matInput [min]="minDate" [max]="maxDate" [matDatepicker]="tatDatePicker" placeholder="Select a date" formControlName="fcTatDate"/>
                    <mat-datepicker-toggle class="ig2" matSuffix [for]="tatDatePicker">
                        <mat-icon matDatepickerToggleIcon (click)="tatDatePicker.open()">
                            <img src="/assets/icons/icon-calendar-dark.svg" alt="calendar icon" />
                        </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker panelClass="ig2" #tatDatePicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field id="due-date" class="ig2 mat-form-field-no-animation mat-form-field-datepicker" appearance="fill">
                    <mat-label>Due Time</mat-label>
                    <input matInput type="time" formControlName="fcTatTime" [ngClass]="{'time-input':detectBrowser()}">
                </mat-form-field>
                
                 <mat-form-field id="due-date" class="ig2 mat-form-field-no-animation mat-form-field-datepicker" appearance="fill">
                    <mat-label>Activation Date </mat-label>
                    <input class="mat-input-element-show-placeholder" matInput [min]="minDate" [max]="maxDate" [matDatepicker]="actDatePicker" placeholder="Select a date" formControlName="fcActDate"/>
                    <mat-datepicker-toggle class="ig2" matSuffix [for]="actDatePicker">
                        <mat-icon matDatepickerToggleIcon (click)="actDatePicker.open()">
                            <img src="/assets/icons/icon-calendar-dark.svg" alt="calendar icon" />
                        </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker panelClass="ig2" #actDatePicker></mat-datepicker>
                </mat-form-field>
                
                <mat-form-field id="due-date" class="ig2 mat-form-field-no-animation mat-form-field-datepicker" appearance="fill">
                    <mat-label>Activation Time</mat-label>
                    <input matInput type="time" formControlName="fcActTime" [ngClass]="{'time-input':detectBrowser()}">
                </mat-form-field>

                
                <mat-form-field id="due-date" class="ig2 mat-form-field-no-animation mat-form-field-datepicker" appearance="fill">
                    <mat-label>Created Date </mat-label>
                    <input class="mat-input-element-show-placeholder" matInput [min]="minDate" [max]="maxDate" [matDatepicker]="createdDatePicker" placeholder="Select a date" formControlName="fcCreatedDate"/>
                    <mat-datepicker-toggle class="ig2" matSuffix [for]="createdDatePicker">
                        <mat-icon matDatepickerToggleIcon (click)="createdDatePicker.open()">
                            <img src="/assets/icons/icon-calendar-dark.svg" alt="calendar icon" />
                        </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker panelClass="ig2" #createdDatePicker></mat-datepicker>
                </mat-form-field>

                
                <mat-form-field id="due-date" class="ig2 mat-form-field-no-animation mat-form-field-datepicker" appearance="fill">
                    <mat-label>Created Time</mat-label>
                    <input matInput type="time" formControlName="fcCreatedTime" [ngClass]="{'time-input-r2':detectBrowser()}">
                </mat-form-field>
                
                
                <mat-form-field id="due-date" class="ig2 mat-form-field-no-animation mat-form-field-datepicker" appearance="fill">
                    <mat-label>Completed Date </mat-label>
                    <input class="mat-input-element-show-placeholder" matInput [min]="minDate" [max]="maxDate" [matDatepicker]="compDatePicker" placeholder="Select a date" formControlName="compDate"/>
                    <mat-datepicker-toggle class="ig2" matSuffix [for]="compDatePicker">
                        <mat-icon matDatepickerToggleIcon (click)="compDatePicker.open()">
                            <img src="/assets/icons/icon-calendar-dark.svg" alt="calendar icon" />
                        </mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker panelClass="ig2" #compDatePicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field id="due-date" class="ig2 mat-form-field-no-animation mat-form-field-datepicker" appearance="fill">
                    <mat-label>Completed Time</mat-label>
                    <input matInput type="time" formControlName="fcCompTime" [ngClass]="{'time-input-r2':detectBrowser()}">
                </mat-form-field> 
                
            </form>
        
        <div class="world-clock">
            <div>
                <span class="headr">World Clock</span>
            </div>
            <div>
                <div class="time-blocks" *ngFor="let time of worldClocks">
                    <div class="time-block-header">
                        {{time.timezoneDay}}, {{time.timezoneOffset}}HRS
                    </div>
                    <div class="time-block-content">
                        {{time.timezoneName}} ({{time.timezoneAbbreviation}})  <span class="time-zone">{{time.timezoneTime}}</span><span class="suffix">{{time.timezoneAMPM}}</span>
                    </div>
                </div>      
            </div>
          </div>  
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <section class="footer">
        <button mat-flat-button mat-dialog-close type="button" class="mat-focus-indicator ig2 button button--clear mat-flat-button mat-button-base btn-cancel">Cancel</button>
        <button type="submit" id="done-button" mat-flat-button class="btn-submit ig2 button button--primary button--small mat-button mat-button-base btn-submit" cdkFocusInitial (click)="submitForm()" [disabled]="!hasChange">Done</button>
        </section>
    </mat-dialog-actions>
</div>
   