import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { TaskStatusComponent } from './components/task-status/task-status.component';


import { EmptyRouteComponent } from './empty-route/empty-route.component';

const routes: Routes = [
  {
    path: `${environment.appPath}`,
    component: AppComponent,
    children: [
      {
        path: ':id',
        component: TaskStatusComponent
      }
    ],
  },
  { path: '**', component: EmptyRouteComponent },
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
})

export class AppRoutingModule { }
