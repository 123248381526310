import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Assignee } from '../models/assignee';

@Injectable({
  providedIn: 'root'
})
export class TaskStatusService {

  constructor(private _httpClient: HttpClient) { }

  baseUrl = environment.apiUrl;

  private showSpinnerSubject: BehaviorSubject<boolean> =  new BehaviorSubject<boolean>(false);
  public showSpinner: Observable<boolean> = this.showSpinnerSubject.asObservable();
  private showToast: EventEmitter<{message: string, isError: boolean}> =  new EventEmitter<{message: string, isError: boolean}>();

  setSpinner(value: boolean){
    this.showSpinnerSubject.next(value);
  }

  getAssigneeOnSearch(prdsysId: string, searchText: string): Observable<Assignee[]> {
    const body = {
      PageRequest: {
        PageNumber: 1,
        PageSize: 50
      },
      Filters: [
        {
          Field: "FirstName LastName",
          Value: searchText
        }
      ]
    };
    const url = `${this.baseUrl}/domaindatasearch/staff/v1/user/predictivesearch/prdsys/${prdsysId}`;
    return this._httpClient.post<{
      totalItemCount: number,
      totalPageCount: number,
      pageNumber: number,
      count: number,
      pageSize: number,
      items: Assignee[],
    }>(encodeURI(url), body).pipe(
      map((data: any) => {
        return data.items;
      })
    );
  }

  getStaffNames(processId: string) {
    return this._httpClient.get(`${this.baseUrl}` + `/staffing/staff/v1/process/` + `${processId}` + `/dropdown`)
  }
  getTimeList() {
    return this._httpClient.get(`${this.baseUrl}` + `/staffing/staff/v1/shifttimes`);
  }

  assignTask(staffId: string | undefined, taskId: string) {
    return this._httpClient.post(`${this.baseUrl}` + `/gentask/gentask/v1/task/` + `${taskId}` + `/staff/` + `${staffId}`, null)
  }

  updateTime(dueDate: string, taskId: string) {
    return this._httpClient.patch(`${this.baseUrl}` + `/gentask/gentask/v1/task/` + `${taskId}` + `/duedate`, { DueDate: dueDate })
  }

  UnAssign(taskId: string) {
    return this._httpClient.post(`${this.baseUrl}/gentask/gentask/v1/task/${taskId}/unassign`, null);
  }

  updateDates(taskId: string, times:any){
    return this._httpClient.post(`${this.baseUrl}` + `/gentask/gentask/v1/task/` + `${taskId}` + `/changetasktimes`, times)
  }
}
