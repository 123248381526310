<!-- <igps-lib-spinner [showSpinner]="showSpinner"></igps-lib-spinner> -->

<mat-form-field class="ig2 mat-form-field-no-animation" appearance="fill">
    <mat-label>{{labelName}}</mat-label>
    <input type="text" placeholder="Pick one" aria-label="Number" [value]="selectedTime" matInput (click)="openDialog()" (keypress)="openDialog()" [disabled]="disabledStatus">
</mat-form-field>



<ng-template #timePicker class="timepicker">
    <!-- <mat-form-field class="ig2 mat-form-field-no-animation" appearance="fill">
        <mat-select id="operators" class="ig2" placeholder="--Select--" [(ngModel)]="selectedTimeZone">
            <mat-option [value]="item.value" *ngFor="let item of timeZoneList">
                {{item.name + " (" + item.abbreviation + ")"}} 
            </mat-option>
        </mat-select>
    </mat-form-field> -->
    <input maxlength="2" (keyup)="onHrsSelection($event)" (change)="onHrsSelection($event)" [(ngModel)]="selectedHrs">
    <input maxlength="2" (keyup)="onMintsSelection($event)" (change)="onMintsSelection($event)" [(ngModel)]="selectedMints">
    <button class="ig2.mat-button-base.button--primary" (click)="onReferenceTimeBtnClick()">{{getTimeFormat()}}</button>
    <button id="btnSave" type="button" (click)="onSave()" mat-flat-button  class="ig2 button button--primary mat-button-base">
        Save
    </button>
</ng-template>