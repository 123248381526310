export const getTimezones: () => {
    name: string;
    abbreviation: string;
    value: string;
}[] = () => {
    let timezones: {
        name: string;
        abbreviation: string;
        value: string;
    }[] = [
            {
                name: "Pacific Time",
                abbreviation: "",
                value: "US/Pacific"
            },
            {
                name: "Arizona Time",
                abbreviation: "",
                value: "US/Arizona"
            },
            {
                name: "Mountain Time",
                abbreviation: "",
                value: "US/Mountain"
            },
            {
                name: "Central Time",
                abbreviation: "",
                value: "US/Central"
            },
            {
                name: "Eastern Time",
                abbreviation: "",
                value: "US/Eastern"
            },
            {
                name: "Hawaii Time",
                abbreviation: "",
                value: "US/Hawaii"
            },
            {
                name: "Alaska Time",
                abbreviation: "",
                value: "US/Alaska"
            },
            {
                name: "Indian Time",
                abbreviation: "",
                value: "Asia/Calcutta"
            },
        ];
    const local = {
        name: "Local Time",
        abbreviation: "",
        value: "local"
    };
    timezones = timezones.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    timezones.unshift(local);
    return timezones;
}

export const getWorldClock: () => {
    timezoneName: string;
    timezoneValue: string;
    timezoneAbbreviation: string;
    timezoneOffset: number;
    timezoneAMPM: string;
    timezoneTime: string;
    timezoneDay: string;
}[] = () => {
    return [
        {
            timezoneName: "Arizona",
            timezoneValue: "US/Arizona",
            timezoneAbbreviation: "",
            timezoneOffset: 0,
            timezoneAMPM: "",
            timezoneTime: "",
            timezoneDay: ""
        },
        {
            timezoneName: "Atlantic",
            timezoneValue: "America/Dominica",
            timezoneAbbreviation: "",
            timezoneOffset: 0,
            timezoneAMPM: "",
            timezoneTime: "",
            timezoneDay: ""
        },
        {
            timezoneName: "Central",
            timezoneValue: "US/Central",
            timezoneAbbreviation: "",
            timezoneOffset: 0,
            timezoneAMPM: "",
            timezoneTime: "",
            timezoneDay: ""
        },
        {
            timezoneName: "Eastern",
            timezoneValue: "US/Eastern",
            timezoneAbbreviation: "",
            timezoneOffset: 0,
            timezoneAMPM: "",
            timezoneTime: "",
            timezoneDay: ""
        },
        {
            timezoneName: "Hawaii",
            timezoneValue: "US/Hawaii",
            timezoneAbbreviation: "",
            timezoneOffset: 0,
            timezoneAMPM: "",
            timezoneTime: "",
            timezoneDay: ""
        },
        {
            timezoneName: "Indian",
            timezoneValue: "Asia/Calcutta",
            timezoneAbbreviation: "",
            timezoneOffset: 0,
            timezoneAMPM: "",
            timezoneTime: "",
            timezoneDay: ""
        },
        {
            timezoneName: "Mountain",
            timezoneValue: "US/Mountain",
            timezoneAbbreviation: "",
            timezoneOffset: 0,
            timezoneAMPM: "",
            timezoneTime: "",
            timezoneDay: ""
        },
        {
            timezoneName: "Pacific",
            timezoneValue: "US/Pacific",
            timezoneAbbreviation: "",
            timezoneOffset: 0,
            timezoneAMPM: "",
            timezoneTime: "",
            timezoneDay: ""
        },
        {
            timezoneName: "Philippines",
            timezoneValue: "Asia/Manila",
            timezoneAbbreviation: "",
            timezoneOffset: 0,
            timezoneAMPM: "",
            timezoneTime: "",
            timezoneDay: ""
        }
    ];
}