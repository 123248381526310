import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, NgZone, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConvertTimePipe } from '@igps/pipe';
import { debounceTime, filter, finalize, map, Observable, Subscription, switchMap } from 'rxjs';
import { statusList } from 'src/app/constants/statusList';
import { timeList } from 'src/app/constants/timesList';
import { Assignee } from 'src/app/models/assignee';
import { TaskBasicInfo } from 'src/app/models/taskBasicInfo';
import { Time } from 'src/app/models/time';
import { TaskStatusService } from '../../services/task-status.service';
import { getUserInfo, getTimezone, timezone$ } from '@igps/client-root-config'
import { UserInfo } from 'src/app/models/userInfo';
import { GetTaskBasicInfo, ReloadTaskBasicInfoEvent, TaskBasicInfoEvent, ProcessDataEvent, GetProcessData } from '@igps/file-info-header';
import { DatePipe } from '@angular/common';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { EditTaskDatesPopUpComponent } from '../edit-task-dates-pop-up/edit-task-dates-pop-up.component';
import { ProcessDataModel } from 'src/app/models/processDataModel';
import { UtilityService } from 'src/app/services/utility.service';
import { mountRootParcel } from 'single-spa';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
@Component({
  selector: 'app-task-status',
  templateUrl: './task-status.component.html',
  styleUrls: ['./task-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskStatusComponent implements OnInit, OnDestroy {

  search: EventEmitter<any> = new EventEmitter<any>();
  searchEventSubscription!: Subscription;

  searchText: string = "";
  isUsersLoading: boolean = false;


  currentDate = new Date();
  isError!: boolean;
  errorMessage!: string;
  basicInfo!: TaskBasicInfo;
  taskInformationSubscription$!: Subscription;
  taskStatusForm!: FormGroup;
  taskInfoForm!: FormGroup;
  statusList = statusList
  minDate!: any;
  staffList!: Assignee[];
  staffListBackup!: Assignee[];
  staffsListSubscription$!: Subscription;
  timesListSubscription$!: Subscription;
  processData!: ProcessDataModel;
  processDataSubscription$!: Subscription;
  timesList!: Time[];
  controlNames = { fcStatus: 'fcStatus', fcTatDate: 'fcTatDate', fcAssignedTo: 'fcAssignedTo', fcTatTime: 'fcTatTime' }

  taskId!: string;
  taskId$!: Observable<string>;
  userInfo!: UserInfo;
  maxDate!: Date;
  selecedTime!: string;
  userSelectedTime!: string
  timeFormat!: string;
  disabledTimeControl: boolean = true;
  selectedTimeZone!: any;
  setAssigneeValueEvent: EventEmitter<string> = new EventEmitter<string>();

  timezoneSubscription!: Subscription;
  timezone: { name: string; value: string; abbreviation: string } = { name: "", value: "", abbreviation: "" };
  emailId!: any;
  isEmail: boolean = false;
  emailProps = {}
  mountRootParcel = mountRootParcel;
  config(): Promise<any> {
    return (window as any).System.import('@igps/email-message');
  }
  constructor(private _taskStatusService: TaskStatusService, private utilityService: UtilityService, private _formBuilder: FormBuilder, private _datePipe: ConvertTimePipe, private cdref: ChangeDetectorRef, private aDatePipe: DatePipe, private zone: NgZone, private dialog: MatDialog, private router: Router, private route: ActivatedRoute) {

  }
  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.emailId = params.get('emailId');
      if(this.emailId)
      {
        this.isEmail = true;
        this.emailProps = { emailId: this.emailId }
      }
    })
    this.timezone = getTimezone();
    this.timezoneSubscription = timezone$.subscribe((timezone: { name: string; value: string; abbreviation: string }) => {
      this.timezone = timezone;
      if (this.basicInfo) {
        this.setFormValues();
      }
    });
    this.userInfo = getUserInfo();
    this.maxDate = new Date();
    this.maxDate?.setFullYear(this.currentDate?.getFullYear() + 1);
    this.processDataSubscription$ = ProcessDataEvent.subscribe(data => {
      this.processData = data;
      this.enableDisableDueDateToggle();
    });
    if (!this.processData) {
      this.processData = GetProcessData();
      this.enableDisableDueDateToggle();
    }
    this.taskInformationSubscription$ = TaskBasicInfoEvent.subscribe(data => {
      this.basicInfo = data.taskinfo;
      this.initializeForm();
      this.getAssignedToList(this.basicInfo?.processId, data.isBackgroundRefresh);
    });
    if (!this.basicInfo) {
      this.basicInfo = GetTaskBasicInfo();
      if (this.basicInfo) {
        this.initializeForm();
        this.getAssignedToList(this.basicInfo?.processId, false);
      }
    }
    this.timesList = timeList;
    const searchObservable = this.search.asObservable().pipe(
      map((d: any) => {
        this.isUsersLoading = true;
        this.searchText = d;
        return this.searchText;
      }),
      map((d: any) => {
        this.staffList = [];
        if (d.trim().length === 0) {
          this.isUsersLoading = false;
          this.staffList = JSON.parse(JSON.stringify(this.staffListBackup)) as Assignee[];
          this.cdref.detectChanges();
        }
        return d;
      }),
      filter((d: any) => d.trim().length > 0),
      debounceTime(500),
      switchMap((d: any) => {
        const prdsysId = getUserInfo().productionSystem.id;
        return this._taskStatusService.getAssigneeOnSearch(prdsysId, d).pipe(
          finalize(() => {
            this.isUsersLoading = false;
            this.cdref.detectChanges();
          })
        );
      })
    );
    this.searchEventSubscription = searchObservable.subscribe(assigneeData => {
      if (this.searchText.trim().length !== 0) {
        assigneeData = assigneeData.map((d: any) => {
          return { ...d, role: d.role.charAt(0).toUpperCase() + d.role.slice(1).toLowerCase() };
        });
        this.staffList = assigneeData;
      }
      this.cdref.detectChanges();
    });
  }
  getAssignedToList(processId: string, isBackground: boolean) {
    if (!isBackground) {
      this.utilityService.setSpinner(true);
    }
    this.staffsListSubscription$ = this._taskStatusService.getStaffNames(processId).pipe(
      finalize(() => {
        this.utilityService.setSpinner(false);
      })
    ).subscribe({
      next: (result) => {
        this.staffList = result as Assignee[];
        this.staffList = this.staffList.map(s => {
          return { ...s, site: (s as any).siteName }
        });

        const mySelf: Assignee = {
          id: this.userInfo.id,
          name: `Myself`,
          role: this.userInfo.role.name,
          site: this.userInfo.site.name
        }
        const unAssigned: Assignee = {
          id: "null",
          name: 'UnAssigned',
          role: '',
          site: ""
        }
        this.staffList.unshift(mySelf);
        this.staffList.unshift(unAssigned);

        this.staffList = this.staffList.map(d => {
          return { ...d, role: d.role.charAt(0).toUpperCase() + d.role.slice(1).toLowerCase() };
        })
        this.staffListBackup = JSON.parse(JSON.stringify(this.staffList)) as Assignee[];
        this.setFormValues();
      },
      error: (error: HttpErrorResponse) => {
        this.utilityService.openToast(error?.error.Detail, true);
      }
    });
  }

  initializeForm() {
    this.zone.run(() => {
      this.taskInfoForm = this._formBuilder.group(
        {
          fcStatus: [{ value: '', disabled: true }, []],
          fcTatDate: [],
          fcAssignedTo: [{ value: '', disabled: !((this.userInfo?.assumedRoleId === 1 && !(this.basicInfo?.taskStatus?.id === 7 || this.basicInfo?.taskStatus?.id === 777 || this.basicInfo?.taskStatus?.id === 6 || this.basicInfo?.taskStatus?.id === 9)) || this.userInfo.role.id === 1000) }, []]
        });
    })
  }

  setFormValues() {
    const timezoneTime = this._datePipe.convertTimeFromOneTimeZoneToAnother(this.basicInfo.dueDateUtc, 'utc', this.timezone.value);
    const orderedDateUtcTimezoneTime = this._datePipe.convertTimeFromOneTimeZoneToAnother(this.basicInfo.orderedDateUtc, 'utc', this.timezone.value);
    this.minDate = new Date(orderedDateUtcTimezoneTime);
    const time = this._datePipe.convertTimeFromOneTimeZoneToAnother(this.basicInfo.dueDateUtc, 'utc', this.timezone.value).split('T')[1];
    this.selecedTime = this._datePipe.changeTimeFormat(time, 'hh:mm a')!;
    this.timeFormat = this._datePipe.changeTimeFormat(time, 'a')!;
    const assignee = !!this.basicInfo.assignee ? this.staffList.find((item) => item.id === this.basicInfo.assignee!.id)!.id : 'null';
    this.zone.run(() => {
      this.taskInfoForm.controls[this.controlNames.fcTatDate].setValue(timezoneTime)
      this.taskInfoForm.controls[this.controlNames.fcStatus].setValue(this.statusList.find((item) => item.id === this.basicInfo?.taskStatus.id)!.id);
      this.setAssigneeValueEvent.emit(assignee);
      this.enableDisableDueDateToggle();
    })
  }

  enableDisableDueDateToggle() {
    if (this.userInfo.role.id === 1000 || (this.userInfo.id === this.basicInfo?.assignee?.id && this.processData?.editableDueDates)) {
      this.taskInfoForm?.controls[this.controlNames.fcTatDate]?.enable();
      this.disabledTimeControl = false;
    }
    else {
      this.taskInfoForm?.controls[this.controlNames.fcTatDate]?.disable();
      this.disabledTimeControl = true;
    }
    this.cdref.detectChanges();
  }

  onOpenChangeAssignee() {
    setTimeout(() => {
      if (!this.utilityService.getSpinnerValue()) {
        this.staffList = JSON.parse(JSON.stringify(this.staffListBackup)) as Assignee[];
        const assignee = !!this.basicInfo.assignee ? this.staffList.find((item) => item.id === this.basicInfo.assignee!.id)!.id : 'null';
        this.setAssigneeValueEvent.emit(assignee);
      }
    }, 100);
  }

  public openEditDatesPopup(): void {
    const dialogRef = this.dialog.open(EditTaskDatesPopUpComponent, {
      panelClass: 'ig2',
      width: '580px',
      disableClose: true,
      data: {
        basicFileInfo: this.basicInfo,
        processInfo: this.processData
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      // do nothing.
    });
  }

  onTimeSelection(timeInfo: {
    selectedTime: string;
    timeZone: string;
  }) {

    this.userSelectedTime = timeInfo.selectedTime;
    // tslint:disable-next-line:no-string-literal
    this.selectedTimeZone = timeInfo.timeZone;
    this.onDateTimeChange();
  }

  onAssignToValueChange(event: MatSelectChange) {
    this.zone.run(() => {
      const assignedTo: string = event.value;
      if (this.basicInfo) {
        if (assignedTo === 'null') {
          this.utilityService.setSpinner(true);
          this._taskStatusService.UnAssign(this.basicInfo.id).pipe(
            finalize(() => {
              this.utilityService.setSpinner(false);
            })
          ).subscribe({
            next: () => {
              this.utilityService.openToast('Your Changes are saved', false);
              ReloadTaskBasicInfoEvent.emit();
            },
            error: (error: HttpErrorResponse) => {
              this.utilityService.openToast(error?.error.Detail, true);
              this.staffList = JSON.parse(JSON.stringify(this.staffListBackup)) as Assignee[];
              const assignee = !!this.basicInfo.assignee ? this.staffList.find((item) => item.id === this.basicInfo.assignee!.id)!.id : 'null';
              this.setAssigneeValueEvent.emit(assignee);
            }
          });
        }
        else {
          let assigneId: any | undefined = '';
          assigneId = this.staffList?.find((sl) => sl?.id === assignedTo)?.id
          this.utilityService.setSpinner(true);
          this._taskStatusService.assignTask(assigneId, this.basicInfo.id).pipe(
            finalize(() => {
              this.utilityService.setSpinner(false);
            })
          ).subscribe({
            next: () => {
              this.utilityService.openToast('Your Changes are saved', false)
              ReloadTaskBasicInfoEvent.emit();
            },
            error: (error: HttpErrorResponse) => {
              this.utilityService.openToast(error?.error.Detail, true);
              this.staffList = JSON.parse(JSON.stringify(this.staffListBackup)) as Assignee[];
              const assignee = !!this.basicInfo.assignee ? this.staffList.find((item) => item.id === this.basicInfo.assignee!.id)!.id : 'null';
              this.setAssigneeValueEvent.emit(assignee);
            }
          });
        }
      }
    })
  }

  onDateTimeChange() {
    if (!this.userSelectedTime) {
      this.userSelectedTime = this.selecedTime;
    }
    if (!this.selectedTimeZone) {
      this.selectedTimeZone = this.timezone.value;
    }
    // tslint:disable-next-line:no-string-literal
    const tatDate: string = this.aDatePipe.transform(this.taskInfoForm.controls['fcTatDate'].value, 'yyyy-MM-dd')!;
    const tatTime = this._datePipe.changeTimeFormat(this.userSelectedTime, 'HH:mm:ss',);
    const timezoneDateTime = `${tatDate}T${tatTime}`;
    const utc = this._datePipe.convertTimeFromOneTimeZoneToAnother(timezoneDateTime, this.selectedTimeZone, 'utc') + 'Z';
    this.utilityService.setSpinner(true);
    this._taskStatusService.updateTime(utc, this.basicInfo.id).pipe(
      finalize(() => {
        this.utilityService.setSpinner(false);
      })
    ).subscribe({
      next: () => {
        this.utilityService.openToast('Your Changes are saved', false);
        ReloadTaskBasicInfoEvent.emit();
      },
      error: (error: HttpErrorResponse) => {
        // tslint:disable-next-line:no-string-literal
        this.utilityService.openToast(error.error.Detail, true);
      }
    });
  }

  ngOnDestroy(): void {
    this.taskInformationSubscription$?.unsubscribe();
    this.processDataSubscription$?.unsubscribe();
    this.staffsListSubscription$?.unsubscribe();
    this.searchEventSubscription?.unsubscribe();
    this.timezoneSubscription?.unsubscribe();
  }

}


