import { ChangeDetectorRef, Component, NgZone, OnInit, Inject, OnDestroy } from '@angular/core';
import { TaskBasicInfo } from 'src/app/models/taskBasicInfo';
import { Subscription, finalize } from 'rxjs';
import { ConvertTimePipe } from '@igps/pipe';
import { FormBuilder, FormGroup } from '@angular/forms';
import { getUserInfo, getTimezone, timezone$ } from '@igps/client-root-config'
import { UserInfo } from 'src/app/models/userInfo';
import { DatePipe } from '@angular/common';
import { TaskStatusService } from 'src/app/services/task-status.service';
import { statusList } from 'src/app/constants/statusList';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getWorldClock } from 'src/assets/timezones';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-edit-task-dates-pop-up',
  templateUrl: './edit-task-dates-pop-up.component.html',
  styleUrls: ['./edit-task-dates-pop-up.component.scss']
})
export class EditTaskDatesPopUpComponent implements OnInit, OnDestroy {

  minDate!: any;
  basicInfo!: TaskBasicInfo;
  EditTaskDatesForm!: FormGroup;
  userInfo!: UserInfo;
  utcEndDate!:string |null;
  dueDateUtc!:string;
  isError!: boolean;
  errorMessage!: string;
  statusList = statusList;
  currentDate = new Date();
  worldClocks:{
    timezoneName: string;
    timezoneValue: string;
    timezoneAbbreviation:string;
    timezoneOffset: number;
    timezoneAMPM: string;
    timezoneTime:string;
    timezoneDay:string;
  }[]= [];
  maxDate!: Date;
  disabledTimeControl: boolean = false;
  processData!: any;
  processDataSubscription$!:Subscription;
  controlNames = { fcStatus: 'fcStatus', fcTatDate: 'fcTatDate', fcAssignedTo: 'fcAssignedTo', fcTatTime: 'fcTatTime', fcActDate:'fcActDate', fcActTime:'fcActTime', compDate:'compDate', fcCompTime:'fcCompTime',fcCreatedDate:'fcCreatedDate', fcCreatedTime:'fcCreatedTime'}
  timezoneSubscription!: Subscription;
  timezone: { name: string; value: string; abbreviation: string } = { name: "", value: "", abbreviation: "" };
  initalValues: any;
  hasChange: boolean = false;

  constructor(private _datePipe: ConvertTimePipe,private aDatePipe: DatePipe , private utilityService: UtilityService, private zone: NgZone,private _formBuilder: FormBuilder,private cdref: ChangeDetectorRef,private _taskStatusService: TaskStatusService, public _dialogRef: MatDialogRef<EditTaskDatesPopUpComponent>,@Inject(MAT_DIALOG_DATA) public data: any ) { }

  ngOnDestroy(): void {
    this.timezoneSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.userInfo = getUserInfo();
    this.maxDate = new Date();
    this.maxDate?.setFullYear(this.currentDate?.getFullYear() + 1);
    this.timezone = getTimezone();
    this.initializeForm();
    this.timezoneSubscription = timezone$.subscribe((timezone: { name: string; value: string; abbreviation: string }) => {
      this.timezone = timezone;
      this.basicInfo = this.data.basicFileInfo;
      this.processData = this.data.processInfo;
      this.basicInfo.dueDateUtc = this.basicInfo.dueDateUtc ? this.basicInfo.dueDateUtc.split(':').splice(0,2).join(":")+":00Z" : this.basicInfo.dueDateUtc;
      this.basicInfo.endDate = this.basicInfo.endDate ? this.basicInfo.endDate.split(':').splice(0,2).join(":")+":00Z" : this.basicInfo.endDate;
      this.basicInfo.orderedDateUtc = this.basicInfo.orderedDateUtc ? this.basicInfo.orderedDateUtc.split(':').splice(0,2).join(":")+":00Z" : this.basicInfo.orderedDateUtc;
      this.getWorldClocksData();
      if (this.basicInfo) {
        this.setFormValues();
      }
    });
  }

  initializeForm() {
    this.zone.run(() => {
      this.EditTaskDatesForm = this._formBuilder.group(
        {
          fcTatDate: [],
          fcTatTime:[],
          fcActDate:[],
          fcActTime:[],
          compDate:[],
          fcCompTime:[],
          fcCreatedDate:[],
          fcCreatedTime:[],
        });
    })
  }

  setFormValues(){
    this.zone.run(() => {
      const dueDateTimezoneTime = this._datePipe.convertTimeFromOneTimeZoneToAnother(this.basicInfo.dueDateUtc, 'utc', this.timezone.value);
      const orderedDateUtcTimezoneTime = this._datePipe.convertTimeFromOneTimeZoneToAnother(this.basicInfo.orderedDateUtc, 'utc', this.timezone.value);
      const activationDateTimezoneTime = this._datePipe.convertTimeFromOneTimeZoneToAnother(this.basicInfo.activationDate, 'utc', this.timezone.value);
      this.dueDateUtc = this._datePipe.convertTimeFromOneTimeZoneToAnother(this.basicInfo.dueDateUtc, 'utc', this.timezone.value);
      this.minDate = new Date(orderedDateUtcTimezoneTime);
      const duetime = dueDateTimezoneTime.split('T')[1];
      const orderTime = orderedDateUtcTimezoneTime.split('T')[1];
      const activationTime = activationDateTimezoneTime.split('T')[1];
      this.EditTaskDatesForm.controls[this.controlNames.fcTatDate].setValue(dueDateTimezoneTime);
      this.EditTaskDatesForm.controls[this.controlNames.fcTatTime].setValue(duetime.substring(0,5));
      this.EditTaskDatesForm.controls[this.controlNames.fcActDate].setValue(activationDateTimezoneTime);
      this.EditTaskDatesForm.controls[this.controlNames.fcActTime].setValue(activationTime.substring(0,5));
      if(this.basicInfo.endDate != null){
        const endDateUtcTimezoneTime = this._datePipe.convertTimeFromOneTimeZoneToAnother(this.basicInfo.endDate, 'utc', this.timezone.value);
        const compconstedUtcTime = endDateUtcTimezoneTime.split('T')[1];
        this.EditTaskDatesForm.controls[this.controlNames.compDate].setValue(endDateUtcTimezoneTime);
        this.EditTaskDatesForm.controls[this.controlNames.fcCompTime].setValue(compconstedUtcTime.substring(0,5));
      }
      this.EditTaskDatesForm.controls[this.controlNames.fcCreatedDate].setValue(orderedDateUtcTimezoneTime);
      this.EditTaskDatesForm.controls[this.controlNames.fcCreatedTime].setValue(orderTime.substring(0,5));
      this.EditTaskDatesForm.controls[this.controlNames.fcTatDate].disable();
      this.EditTaskDatesForm.controls[this.controlNames.fcTatTime].disable();
      this.EditTaskDatesForm.controls[this.controlNames.fcActDate].disable();
      this.EditTaskDatesForm.controls[this.controlNames.fcActTime].disable();
      this.EditTaskDatesForm.controls[this.controlNames.compDate].disable();
      this.EditTaskDatesForm.controls[this.controlNames.fcCompTime].disable();
      this.EditTaskDatesForm.controls[this.controlNames.fcCreatedDate].disable();
      this.EditTaskDatesForm.controls[this.controlNames.fcCreatedTime].disable();
      if(this.processData.editableDueDates && ((this.basicInfo.assignee?.id === this.userInfo.id))){
        this.EditTaskDatesForm.controls[this.controlNames.fcTatDate].enable();
        this.EditTaskDatesForm.controls[this.controlNames.fcTatTime].enable();
      }
      if(this.processData.editableActivationDates && ((this.basicInfo.assignee?.id === this.userInfo.id))){
        this.EditTaskDatesForm.controls[this.controlNames.fcActDate].enable();
        this.EditTaskDatesForm.controls[this.controlNames.fcActTime].enable();
      }
      if(this.userInfo.role.id === 1000){
        this.EditTaskDatesForm.controls[this.controlNames.fcTatDate].enable();
        this.EditTaskDatesForm.controls[this.controlNames.fcTatTime].enable();
        this.EditTaskDatesForm.controls[this.controlNames.fcActDate].enable();
        this.EditTaskDatesForm.controls[this.controlNames.fcActTime].enable();
        this.EditTaskDatesForm.controls[this.controlNames.compDate].enable();
        this.EditTaskDatesForm.controls[this.controlNames.fcCompTime].enable();
        this.EditTaskDatesForm.controls[this.controlNames.fcCreatedDate].enable();
        this.EditTaskDatesForm.controls[this.controlNames.fcCreatedTime].enable();
      }
      this.initalValues = this.EditTaskDatesForm.value;
      this.EditTaskDatesForm.valueChanges.subscribe(() => {
        this.hasChange = JSON.stringify(this.EditTaskDatesForm.value) !== JSON.stringify(this.initalValues);
      });
    })
  }

  submitForm() {
    // tslint:disable-next-line:no-string-literal
    const dueDateTimeZone: string = this.aDatePipe.transform(this.EditTaskDatesForm.controls['fcTatDate'].value, 'yyyy-MM-dd')!;
    // tslint:disable-next-line:no-string-literal
    const dueTimeTimeZone = this.EditTaskDatesForm.controls['fcTatTime'].value;
    const dueDateTimezoneDateTime = `${dueDateTimeZone}T${dueTimeTimeZone}`;
    // tslint:disable-next-line:no-string-literal
    const actDateTimeZone: string = this.aDatePipe.transform(this.EditTaskDatesForm.controls['fcActDate'].value, 'yyyy-MM-dd')!;
    // tslint:disable-next-line:no-string-literal
    const actTimeTimeZone = this.EditTaskDatesForm.controls['fcActTime'].value;
    const actDateTimezoneDateTime = `${actDateTimeZone}T${actTimeTimeZone}`;
    // tslint:disable-next-line:no-string-literal
    const createdDateTimeZone: string = this.aDatePipe.transform(this.EditTaskDatesForm.controls['fcCreatedDate'].value, 'yyyy-MM-dd')!;
    // tslint:disable-next-line:no-string-literal
    const createdTimeTimeZone = this.EditTaskDatesForm.controls['fcCreatedTime'].value;
    const createdDateTimezoneDateTime = `${createdDateTimeZone}T${createdTimeTimeZone}`;
    // tslint:disable-next-line:no-string-literal
    if(this.aDatePipe.transform(this.EditTaskDatesForm.controls['compDate'].value) != null){
      // tslint:disable-next-line:no-string-literal
      const compDateTimeZone: string = this.aDatePipe.transform(this.EditTaskDatesForm.controls['compDate'].value, 'yyyy-MM-dd')!;
      // tslint:disable-next-line:no-string-literal
      const compTimeTimeZone = this.EditTaskDatesForm.controls['fcCompTime'].value;
      const compDateTimezoneDateTime = `${compDateTimeZone}T${compTimeTimeZone}`;
      this.utcEndDate = this._datePipe.convertTimeFromOneTimeZoneToAnother(compDateTimezoneDateTime, this.timezone.value, 'utc') + 'Z';
      if( new Date(this.utcEndDate).getTime()< 0){
        this.utcEndDate = null;
      }
    }
    const utcDueDate = this._datePipe.convertTimeFromOneTimeZoneToAnother(dueDateTimezoneDateTime, this.timezone.value, 'utc') + 'Z';
    const utcActivationDate = this._datePipe.convertTimeFromOneTimeZoneToAnother(actDateTimezoneDateTime, this.timezone.value, 'utc') + 'Z';
    const utcCreatedDate = this._datePipe.convertTimeFromOneTimeZoneToAnother(createdDateTimezoneDateTime, this.timezone.value, 'utc') + 'Z';
    this.utilityService.setSpinner(true);
    const submitEditData: any = {};
    if(utcDueDate !== this.basicInfo.dueDateUtc){
      submitEditData.dueDate = utcDueDate;
    }
    if(utcActivationDate !== this.basicInfo.activationDate){
      submitEditData.activationDate = utcActivationDate;
    }
    if(utcCreatedDate !== this.basicInfo.orderedDateUtc){
      submitEditData.orderDate = utcCreatedDate;
    }
    if(this.utcEndDate !== this.basicInfo.endDate){
      submitEditData.endDate = this.utcEndDate;
    }
    this._taskStatusService.updateDates(this.basicInfo.id, submitEditData).pipe(
      finalize(() => {
        this.utilityService.setSpinner(false);
        this._dialogRef?.close();
      })
    ).subscribe({
      next: () => {
        this.utilityService.openToast('Your Changes are saved', false);
      },
      error: (error: HttpErrorResponse) => {
        this.utilityService.openToast(error.error.Detail, true);
      }
    });
  }

  detectBrowser(){
    if(window.navigator.userAgent.indexOf("Edg") > -1){
      return true;
    }else{
      return false;
    }
  }

  getTimezoneDay(sourceTimezone:string, targetTimezone:string){
    const sourceTimezoneDateTime = new Date(this._datePipe.getCurrentDateTimeinOtherTimezone(sourceTimezone));
    const targetTimezoneDateTime = new Date(this._datePipe.getCurrentDateTimeinOtherTimezone(targetTimezone));
    if(sourceTimezoneDateTime.getDate()=== targetTimezoneDateTime.getDate()){
        return "Today";
    }else if(sourceTimezoneDateTime.getDate()+1 === targetTimezoneDateTime.getDate()){
        return "Tommorrow";
    }else{
      return "Yesterday";
    }
  }

  getWorldClocksData(){
    this.worldClocks = getWorldClock();
    this.worldClocks = this.worldClocks.map(t => {
      return {
        ...t,
        timezoneAbbreviation:this._datePipe.getTimezoneAbbreviation(t.timezoneValue,this._datePipe.getCurrentDateTimeinOtherTimezone(t.timezoneValue))!,
        timezoneOffset:this._datePipe.getTimeZoneOffsetBetweenTwoTimeZones(this._datePipe.getCurrentDateTimeinOtherTimezone(t.timezoneValue),this.timezone.value,t.timezoneValue)!,
        timezoneAMPM:this.aDatePipe.transform(this._datePipe.getCurrentDateTimeinOtherTimezone(t.timezoneValue),"a")!,
        timezoneTime:this.aDatePipe.transform(this._datePipe.getCurrentDateTimeinOtherTimezone(t.timezoneValue),"hh:mm")!,
        timezoneDay:this.getTimezoneDay(this.timezone.value, t.timezoneValue)
      };
    });
  }
}
