import { Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TimeFormat } from '../../enums/timeFormat';
import { getTimezones } from 'src/assets/timezones';
import { Subscription } from 'rxjs';
import { getTimezone, timezone$ } from '@igps/client-root-config';
import { ConvertTimePipe } from '@igps/pipe';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  encapsulation: ViewEncapsulation.None
})




// tslint:disable-next-line:max-classes-per-file
export class TimepickerComponent implements OnInit, OnChanges, OnDestroy {

  @Input() labelName = 'Due Time'
  @Input() selectedTime = '00 : 00';
  @Input() disabledStatus = false;
  @Input() timeFormat!: string;



  @Output() selectTimeEvent = new EventEmitter();
  @ViewChild('timePicker')
  timePicker!: TemplateRef<any>;
  selectedTimeZone!: string;
  isDialogueOpen = false;
  previousTimeFormat!: string;
  selectedHrs: string = '0';
  selectedMints: string = '0';
  showSpinner = false;
  timeZoneList: { name: string; abbreviation: string; value: string; } [] = [];
  selectedTimeZoneValue!: { selectedTime: string; timeZone: string; };

  timezoneSubscription!: Subscription;
  timezone: { name: string; value: string; abbreviation: string } = { name: "", value: "", abbreviation: "" };

  constructor(public dialog: MatDialog,private zone:NgZone, private convertTimePipe: ConvertTimePipe) { }
  // tslint:disable-next-line:no-empty
  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedTime !== undefined) {
      this.selectedHrs = this.selectedTime.split(':')[0].trim();
      this.selectedMints = this.selectedTime.split(':')[1].trim().substring(0, 2)
    }
  }

  openDialog() {
    this.selectedTimeZone = this.timezone.value;
    if (this.disabledStatus) {
      return;
    }
    this.zone.run(()=>{
      const dialogRef = this.dialog.open(this.timePicker,{panelClass:'time-picker-container'});
      this.isDialogueOpen = true;
      dialogRef.afterClosed().subscribe(() => {
        this.isDialogueOpen = false;
      });
    })
  }

  onSave(){
    this.dialog.closeAll();
    this.selectedTimeZoneValue = {
      selectedTime :this.selectedTime,
      timeZone :this.selectedTimeZone
    }
    this.selectTimeEvent.emit(this.selectedTimeZoneValue);
  }
  // tslint:disable-next-line:no-empty
  ngOnInit() {
    this.timezone = getTimezone();
    this.timezoneSubscription = timezone$.subscribe((timezone: { name: string; value: string; abbreviation: string }) => {
      this.timezone = timezone;
    });
    this.timeZoneList = getTimezones().map(t => { return {...t, abbreviation: this.convertTimePipe.getTimezoneAbbreviation(t.value)} });
    this.timeZoneList = this.timeZoneList.filter(t => t.value !== Intl.DateTimeFormat().resolvedOptions().timeZone);
  }



  onHrsSelection(event: any) {
    if ((isNaN(event.key) && !(event.key === 'Backspace' || event.key === 'ArrowUp' || event.key === 'ArrowDown')) || isNaN(event.target.value)) {
      event.preventDefault();
    }
    else {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();
      }
      let latestNumber = this.inputEventValidation(event.key, 'hours');
      if(+latestNumber < 1 || latestNumber.length === 0){
        latestNumber = "01";
      }
      if (+latestNumber > 12 || +latestNumber < 1) {
        event.preventDefault();
      }
      else {
        this.selectedHrs = (+latestNumber).toString();
        this.setTime();
      }
    }
  }

  setTime() {
    if (+this.selectedHrs < 10) {
      this.selectedHrs = `0${+this.selectedHrs}`;
    }
    if (+this.selectedMints < 10) {
      this.selectedMints = `0${+this.selectedMints}`;
    }
    this.selectedTime = this.selectedHrs + ':' + this.selectedMints + ' ' + this.timeFormat;
  }

  onMintsSelection(event: any) {
    if ((isNaN(event.key) && !(event.key === 'Backspace' || event.key === 'ArrowUp' || event.key === 'ArrowDown')) || isNaN(event.target.value)) {
      event.preventDefault();
    }
    else {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();
      }
      const latestNumber = this.inputEventValidation(event.key, 'minutes');
      if (+latestNumber > 59 || +latestNumber < 0) {
        event.preventDefault();
      }
      else {
        this.selectedMints = (+latestNumber).toString();
        this.setTime();
      }
    }
  }

  inputEventValidation(key: string, inputField: string): string {
    let latestNumber = "";
    switch (key) {
      case "ArrowUp":
        latestNumber = inputField === 'hours' ? (+this.selectedHrs + 1).toString() : (+this.selectedMints + 1).toString();
        break;
      case "ArrowDown":
        latestNumber = inputField === 'hours' ? (+this.selectedHrs - 1).toString() : (+this.selectedMints - 1).toString();
        break;
      case "Backspace":
        latestNumber = inputField === 'hours' ? this.selectedHrs : this.selectedMints;
        break;
      default:
        const prevHrs = this.selectedHrs.substring(1, this.selectedHrs.length);
        const prevMin = this.selectedMints.substring(1, this.selectedMints.length);
        if (inputField === 'hours') {
          if (+prevHrs === 1) {
            if((+key > 2)){
              latestNumber = `0${+key}`;
            }
            else{
              latestNumber = prevHrs + (+key);
            }
          }
          else{
            if(+prevHrs === 0){
              latestNumber = prevHrs + (+key);
            }
            else{
              latestNumber = `0${+key}`;
            }
          }
        }
        else {
          if(+prevMin > 5){
            latestNumber = `0${+key}`;
          }
          else{
            latestNumber = prevMin + (+key);
          }
        }
        break;
    }
    return latestNumber;
  }

  getTimeFormat() {
    return this.timeFormat;
  }
  onReferenceTimeBtnClick() {
    if (this.timeFormat === TimeFormat.AM) {
      this.timeFormat = TimeFormat.PM
    }
    else {
      this.timeFormat = TimeFormat.AM

    }
    this.setTime();
  }

  ngOnDestroy(): void {
    this.timezoneSubscription?.unsubscribe();
  }

}
