<div class="task-status-container display-flex">
    <span class="process-name"> {{basicInfo?.processName}} {{basicInfo?.displayName != null? ' :
        '+basicInfo?.displayName : null}} </span>
    <div class="createddate" *ngIf=" basicInfo!==undefined && basicInfo.orderedDateUtc">
        Created on {{basicInfo.orderedDateUtc.toString()| convertTime: 'utc' : timezone.value }}
    </div>
    <form [formGroup]="taskInfoForm" *ngIf="taskInfoForm" autocomplete="off" novalidate>
        <mat-form-field [id]="'status-'+taskInfoForm.controls['fcStatus'].value" class="ig2 mat-form-field-no-animation " appearance="fill">
            <mat-label id="status-label" class="mat-label-wrap">Status</mat-label>
            <mat-select class="ig2" placeholder="--Select--" formControlName="fcStatus">
                <mat-option [value]="item.id" *ngFor="let item of statusList">
                    {{item?.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field id="due-date" class="ig2 mat-form-field-no-animation mat-form-field-datepicker" appearance="fill">
            <mat-label>Due Date
                <button class="ig2 mat-button-tooltip" mat-icon-button type="button" matTooltipClass="ng-trigger" [matTooltip]="'Date and time in ' + timezone.name + ' (' + timezone.abbreviation +'), within which the task is expected to be completed'" aria-label="Button that displays a tooltip when focused or hovered over">
                    <img class="mat-button-tooltip-icon" src="/assets/icons/icon-question-dark.svg" alt="question icon" />
                </button>
            </mat-label>
            <input class="mat-input-element-show-placeholder" matInput [min]="minDate" [max]="maxDate" [matDatepicker]="tatDatePicker" placeholder="Select a date" formControlName="fcTatDate" (dateChange)="onDateTimeChange()" />
            <mat-datepicker-toggle class="ig2" matSuffix [for]="tatDatePicker">
                <mat-icon matDatepickerToggleIcon (click)="tatDatePicker.open()">
                    <img src="/assets/icons/icon-calendar-dark.svg" alt="calendar icon" />
                </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker panelClass="ig2" #tatDatePicker></mat-datepicker>
        </mat-form-field>

        <app-timepicker (selectTimeEvent)="onTimeSelection($event)" [disabledStatus]="disabledTimeControl" [selectedTime]="selecedTime" [timeFormat]="timeFormat"></app-timepicker>

        <div id="Assigneedropdown">
            <reactive-filter-select id="Assigneedropdown" class="ig2" [options]="staffList" label="Assigned To" placeholder="Select Assignee" (searchChange)="search.emit($event)" searchPlaceholder="Search Assignee" [sort]="false" noMatchFoundText="No User Found." [(searchText)]="searchText" [(isLoading)]="isUsersLoading" formControlName="fcAssignedTo" (selectionChange)="onAssignToValueChange($event)" (openedChange)="onOpenChangeAssignee()" [forceShowSearch]="true" [isAPIBind]="true" [setValueEvent]="setAssigneeValueEvent">
                <ng-template let-item #option>
                    <div *ngIf="item.id != 'null'">
                        <div> {{item.name}} </div>
                        <div class="sub-label"> <span>{{item.role}}</span>, <span>{{item.site}}</span> </div>
                    </div>
                    <span *ngIf="item.id == 'null'">{{item.name}}</span>
                </ng-template>
            </reactive-filter-select>
        </div>
        <div id="edit-task-dates">
            <button mat-flat-button class="ig2 button button--secondary button--xsmall" (click)="openEditDatesPopup()">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7594 0.656562L19.3434 2.24063C19.5516 2.44869 19.7167 2.69573 19.8294 2.96763C19.942 3.23952 20 3.53095 20 3.82526C20 4.11956 19.942 4.41099 19.8294 4.68289C19.7167 4.95478 19.5516 5.20182 19.3434 5.40988L7.4607 17.2915C7.35998 17.3922 7.24097 17.4729 7.11005 17.529L1.5647 19.9062C0.634869 20.3039 -0.30392 19.364 0.0937768 18.4353L2.471 12.8899C2.52715 12.759 2.60775 12.64 2.70849 12.5393L14.5901 0.656562C14.7982 0.448412 15.0452 0.283293 15.3171 0.170638C15.589 0.0579836 15.8804 0 16.1747 0C16.4691 0 16.7605 0.0579836 17.0324 0.170638C17.3043 0.283293 17.5513 0.448412 17.7594 0.656562ZM6.66866 16.4995L15.3821 7.78598L12.214 4.61785L3.50053 13.3313L6.66866 16.4995ZM5.55959 16.9756L3.02441 14.4404L1.12331 18.8767L5.55959 16.9756ZM16.1742 6.99395L18.5514 4.61785C18.7614 4.40777 18.8794 4.12287 18.8794 3.82582C18.8794 3.52876 18.7614 3.24386 18.5514 3.03378L16.9673 1.44747C16.8633 1.34332 16.7397 1.26069 16.6037 1.20431C16.4677 1.14793 16.322 1.11891 16.1747 1.11891C16.0275 1.11891 15.8817 1.14793 15.7457 1.20431C15.6097 1.26069 15.4862 1.34332 15.3821 1.44747L13.006 3.8247L16.1742 6.99283V6.99395Z" fill="#043B7C" />
                </svg>
                <span class="hidden-small text-padding">Edit Task Dates</span>
            </button>
        </div>

    </form>
</div>
<div class="email-preview-container display-flex" *ngIf="isEmail">
    <parcel [customProps]="emailProps" [config]="config" [mountParcel]="mountRootParcel"></parcel>
</div>